import React, { useEffect } from "react";
import { useSelect, SelectProps } from "@pankod/refine-antd";
import { Select } from "antd";
import { useOne, useResource } from "@pankod/refine-core";
import _ from "lodash";

// interface
import { IProduct, IProductSelect, IUser } from "interfaces";

// config
import config from "config";

// localstore
import useLocalStorage from "hooks/useLocalStorage";
import { useMe } from "context/MeContext";

type IProps = {
  handleSelectProduct: (_: string, option: any) => void;
  queryProductId: string | null;
  productSelected?: IProductSelect | null;
};

export default function ProductSelectField({ productSelected, queryProductId, handleSelectProduct }: IProps) {
  // states
  const [page, setPage] = React.useState(1);
  const [selectedStudioId] = useLocalStorage("studioId", "");
  const [optionProducts, setOptionProducts] = React.useState<SelectProps["options"]>([]);
  const { data: me, isMember } = useMe();
  const [user] = useLocalStorage<IUser>("user");
  const { resource } = useResource();

  const { queryResult: dataProducts } = useSelect<IProduct>({
    resource: "products",
    filters:
      me?.isOps || !isMember || resource.name === "studios"
        ? [
            {
              field: "studios][id]",
              operator: "eq",
              value: selectedStudioId,
            },
          ]
        : [
            {
              field: "studios][id]",
              operator: "eq",
              value: selectedStudioId,
            },
            {
              field: "product_user_roles][users_permissions_user][id]",
              operator: "eq",
              value: user.id,
            },
          ],

    sort: [
      {
        field: "name",
        order: "asc",
      },
    ],
    fetchSize: config.INITIAL_PAGE_SIZE * 5,
    optionLabel: "name",
    pagination: { current: page },
    queryOptions: {
      keepPreviousData: true,
      onSuccess(data) {
        const normalizedData =
          data.data?.map((item) => ({
            label: item.product_code,
            value: item.id,
            id: item.id,
          })) || [];
        setOptionProducts((prev) => {
          const unique = [...new Set([...(prev || []), ...normalizedData])];
          return _.uniqBy(unique, "id");
        });
      },
      retry: 0,
    },
    successNotification: false,
    errorNotification: false,
  });

  const { data: productDefault } = useOne<IProduct>({
    resource: "products",
    id: Number(queryProductId),
    queryOptions: {
      enabled: Boolean(queryProductId),
      retry: 0,
    },
    errorNotification: false,
  });

  // init product when has queryString on url.
  useEffect(() => {
    if (productSelected?.id || !queryProductId) return;
    const productItem = dataProducts.data?.data.find((item) => item.id.toString() === queryProductId) || null;
    if (productItem) {
      handleSelectProduct("", {
        label: productItem.product_code,
        id: productItem.id,
        value: productItem.id,
      });
      return;
    }
    if (dataProducts.data && dataProducts.data?.total > page * config.INITIAL_PAGE_SIZE) {
      setPage((prevState) => prevState + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDefault, queryProductId, dataProducts, page]);

  return (
    <>
      <Select
        value={productSelected?.label}
        className="w-[200px]"
        dropdownClassName="custom-select"
        placeholder="Products"
        allowClear
        onChange={handleSelectProduct}
        options={optionProducts}
        loading={dataProducts.isFetching}
        onPopupScroll={(event) => {
          const ele = event.currentTarget;
          const isBottom = ele.scrollHeight - ele.scrollTop === ele.clientHeight;
          if (isBottom && dataProducts?.data && dataProducts.data?.total > page * config.INITIAL_PAGE_SIZE) {
            setPage((curr) => curr + 1);
          }
        }}
      />
    </>
  );
}
