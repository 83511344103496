import { Form, FormInstance, Select, useSelect } from "@pankod/refine-antd";
import React from "react";
import config from "config";
import { TDataSelected } from "../Metabase";
import { IProduct, IUser } from "interfaces";
import useLocalStorage from "hooks/useLocalStorage";
import { useMe } from "context/MeContext";
import { useResource } from "@pankod/refine-core";

interface TPropsSelectProduct {
  form: FormInstance<{}>;
}
export default function SelectProduct({ form }: TPropsSelectProduct) {
  const [studioId] = useLocalStorage<string | null | undefined>("studioId");
  const [search, setSearch] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const optionsProduct: TDataSelected[] = Form.useWatch("optionsProduct");
  const dashboardInfo = Form.useWatch("dashboardInfo");
  const productInfo = Form.useWatch("productInfo");
  const { data: me, isMember } = useMe();
  const [user] = useLocalStorage<IUser>("user");
  const { resource } = useResource();
  const { queryResult: queryResultSelectProduct } = useSelect<IProduct>({
    resource: "products",
    filters:
      me?.isOps || !isMember || resource.name === "studios"
        ? [
            {
              field: "studios][id]",
              operator: "eq",
              value: studioId,
            },
          ]
        : [
            {
              field: "studios][id]",
              operator: "eq",
              value: studioId,
            },
            {
              field: "product_user_roles][users_permissions_user][id]",
              operator: "eq",
              value: user.id,
            },
          ],
    sort: [
      {
        field: "name",
        order: "asc",
      },
    ],
    fetchSize: config.INITIAL_PAGE_SIZE,
    optionLabel: "name",
    pagination: { current: page },
    queryOptions: {
      keepPreviousData: true,
      onSuccess(data) {
        const normalizedData = data.data?.map((item) => ({
          label: item.name,
          value: Number(item.id),
        }));
        form.setFieldsValue({
          optionsProduct: search?.length || !optionsProduct ? normalizedData : [...optionsProduct, ...normalizedData],
        });
      },
    },
    onSearch: (value) => {
      setPage(1);
      setSearch(value);
      return [
        {
          field: "name",
          operator: "contains",
          value,
        },
      ];
    },
  });

  const total = queryResultSelectProduct.data?.total;
  const isLoadingQuerySelectProduct = queryResultSelectProduct.isFetching;

  // TODO: Will be removed & refactored it
  const productInfoFiltered = productInfo?.some((product: any) =>
    optionsProduct?.some((option) => option.value === product.value),
  );
  const valuesTrick = productInfoFiltered ? productInfo : [];

  return (
    <Select
      value={valuesTrick}
      mode="multiple"
      className="w-[100%]"
      dropdownClassName="custom-select"
      maxTagCount={"responsive"}
      placeholder="Products"
      disabled={!dashboardInfo}
      onPopupScroll={(event) => {
        const target = event.currentTarget;

        const isEnd = Math.ceil(target.scrollTop + target.offsetHeight) >= target.scrollHeight - 20;
        const shouldNextPage =
          isEnd && total && page <= total % config.INITIAL_PAGE_SIZE && !isLoadingQuerySelectProduct;
        if (shouldNextPage) {
          setPage((curr) => curr + 1);
        }
      }}
      allowClear
      onClear={() => {
        form.setFieldsValue({
          appInfo: [],
          iframeUrl: "",
        });
      }}
      options={optionsProduct}
      onChange={(ids) => {
        form.setFieldsValue({
          productInfo: optionsProduct.filter((v: TDataSelected) => ids.includes(v.value)),
          appInfo: [],
          iframeUrl: "",
        });
      }}
      filterOption={(input, option) => {
        return (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase());
      }}
    />
  );
}
